@import "variables";
@import "mixins";


.payment-status-table-part {
    .payment-status-table {
        tbody {
            tr {
                td {
                    .payment {
                        text-transform: capitalize;
                        margin: 0.25rem;

                        &.paid,
                        &.Paid {
                            @include acceptedStatus;
                        }

                        &.pending,
                        &.Pending {
                            @include pendingStatus;
                        }
                    }

                    .attachment {
                        cursor: pointer;
                        font-size: 1.2rem !important;
                        color: $primaryColor;
                    }
                }
            }
        }
    }
}


.payment-details-part {
    padding: 1rem;
    min-height: 85vh;
    max-height: 90vh;
    overflow-x: hidden;

    .payment-details-card {
        margin: 1rem 2rem;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: $card_box_shadow;

        .br-gray {
            border-right: 1.5px solid $iconBackground;
        }

        .bl-gray {
            border-left: 1.5px solid $iconBackground;
        }
    }

    .payment-details-table-part {
        margin: 0.5rem;

        .css-41abqd-MuiTableContainer-root,
        .MuiTableContainer-root {
            min-height: 30vh !important;
            max-height: 30vh !important;
            overflow-x: hidden;
        }
    }
}