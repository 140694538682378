@import "variables";
@import "mixins";

.page-content-center {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    min-height: 82vh;

    .admin-login-img {        
        border-right: 1px solid $borderColor;
    }

    .admin-otp-form {
        .page-heading h6 {
            font-size: 28px !important;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 50px;
        text-align: center;

        .otp-text {
            padding: 1px 10px 1px;
        }

        .admin-form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px;

            .form-field-div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0px;

                input {
                    width: 40px;
                    margin-right: 8px;
                }
            }

            p {
                // padding-bottom: 10px;
                color: $primaryColor;
            }

            .resend-otp {
                color: $secondaryColor;
                background-color: transparent;
                border: none;
                font-size: $fz12;
            }
        }
    }
}


.subscription-data-card {
    border-radius: 0px !important;
    border: none;
    background-color: transparent;
    width: 100%;
    .subscription-card-body {
            padding: 15px;
            min-height: 85px;
            border-radius: 6px;
            box-shadow: $card_box_shadow;
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;
          
    
            .subscription-card-row,
            .card-data {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }
}

.super-admin-page {
    padding: 20px;

    .page-heading {
        margin: 5px auto;
        padding: 10px;

        h6 {
            font-size: $fz20;
            font-weight: $fwt600;
            color: $secondaryColor;
        }
    }

    .super-admin-controls {
        // background-color: $lightGreen;
        box-shadow: $card_shadow_hover;
        margin: 10px auto;
        padding: 15px;
        border-radius: 10px;

        .superadmin-links {
            display: flex;
            flex-wrap: wrap;
            // justify-content: left;
            justify-content: space-between;
            padding: 15px 10px;

            &.hyperlinks {
                border-bottom: 2px dotted $lightGray;
            }

            .single-card {
                margin: 12px;
                border: none;
                min-width: 45%;
                max-width: 45%;
                min-height: 60px;
                max-height: 60px;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                // box-shadow: 0px 0px 6px -2.9px #7ce5aa;
                box-shadow: $card_box_shadow;
                position: relative;

                &:hover {
                    box-shadow: $card_shadow_hover;
                    cursor: pointer;
                }

                .card-number {
                    background-color: $inputBox_backgroundColor;
                    width: 3rem;
                    height: 2.6rem;
                    margin-left: 10px;
                    text-align: center;                                     
                    font-size: 1.35rem;                   
                    border-radius: 50%;
                    color: $primaryColor;
                    position: relative;
                    .fa{
                        @include hvCenterUsingPositionAbsolute;
                    }

                    p {
                        font-size: $fz20;
                        color: $whiteColor;
                        font-weight: $fwt600;

                    }
                }

                .card-heading {
                    width: 100%;
                    text-align: left;
                    padding: 10px;

                    p {
                        font-size: $fz14;
                        font-weight: $fwt500;
                    }
                }

                .card-arrow {
                    right: 15px;
                    width: 25px;
                    height: 26px;
                    border: 1px solid $secondaryColor;
                    border-radius: 6px;
                    position: absolute;
                    font-size: 15px;
                    padding-left: 4px;
                    color: $secondaryColor;
                }
            }
        }
    }
}

.leads-card-price {
    p {
        font-size: 12px !important;
        color: $primaryText_Color;

        @media screen and (min-width: 1200px) and (max-width: 1600px) {
            font-size: 10px !important;
        }

        @media screen and (min-width: 1601px) and (max-width: 1920px) {
            font-size: 12px !important;
        }

        @media screen and (min-width: 1921px) and (max-width: 2560px) {
            font-size: 14px !important;
        }
    }

    .leads-card {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-top: 7px;

        b {
            font-size: 20px !important;

            @media screen and (min-width: 1200px) and (max-width: 1600px) {
                font-size: 22px !important;
            }

            @media screen and (min-width: 1601px) and (max-width: 1920px) {
                font-size: 24px !important;
            }

            @media screen and (min-width: 1921px) and (max-width: 2560px) {
                font-size: 28px !important;
            }
        }

        img {
            height: 13px;
            width: 13px;
        }

        span {
            font-size: 16px;
            font-weight: 500;
        }

        p {
            color: $successColor !important;
        }
    }
}

