@import "variables";
@import "mixins";

.purchase-order-part, .po-details-table-part {
    .po-table {
        .purchase-order-table,
        .po-details-table {
            tbody {
                tr {
                    td {
                        .po {
                            text-transform: capitalize;

                            &.pending,
                            &.Pending {
                                @include pendingStatus;
                            }

                            &.approved,
                            &.Approved {
                                @include acceptedStatus;
                            }

                            &.rejected,
                            &.Rejected {
                                @include cancelledStatus;
                            }
                        }
                    }
                }
            }
        }
    }
}


// ADD EDIT MODEL //
.add-po_headers-to-vhd,
.add-po_lineItems-to-vhd {
    margin-left: 1.5rem;
    width: 85%;
}


.add-po_lineItems-details {
    border-bottom: 2px solid $primarySemiLight;
    padding: 0.85rem 0;
    position: relative;

    &:first-child {
        .remove-row {
            display: none !important;
        }
    }

    .remove-row {
        cursor: pointer;
        position: absolute;
        top: 1.8rem;
        display: block;
        right: -0.8rem;

        i {
            font-size: 1.25rem;
        }
    }
}

// ADD EDIT MODEL //