@import "variables";
@import "mixins";

.complaint-table {
    @include tableProperty;
}

.complaint-scrollbar {
    height: calc(100vh - 180px);
    margin: 10px;
}

.complaint-thead {
    @include tableHeadProperty;
}

.btn-add-complaint {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: 14px;
        margin-left: 2px;
    }
}

.row-img-amc {
    display: flex;
    align-items: center;

    .amc-img-box {
        position: relative;
        display: flex;
        // background-color: $inputBox_backgroundColor;
        background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
        border: 1px solid $lightGray;
        border-radius: 5px;
        padding: 3px;
        width: 50px !important;
        height: 50px !important;

        .row-amc-images {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.file-upload {
    max-width: 240px;
}

.text-muted {
    color: red !important;
}

.complaint-table-row-data .complaint-edit-link {
    color: $primaryColor;
    font-size: $fz13;
    padding: 0px 5px !important;
}

.view-amcdetails-model {
    .modal-content {
        width: 800px;
        position: relative;
        right: 120px;
    }
}

.amc-image-box {
    position: relative;
    display: flex;
    margin: 10px;
    width: 100px !important;
    height: 100px !important;
    align-items: center;
    justify-items: center;
    padding: 5px;
    // background-color: $lightGray;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);

    .amc-images {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.amc-details-model {
    padding: 20px;

    .amc-detail-left-part {
        .amc-big-img {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            transition: transform .2s;

            &:hover {
                -ms-transform: scale(1.05);
                /* IE 9 */
                -webkit-transform: scale(1.05);
                /* Safari 3-8 */
                transform: scale(1.05);
            }

            .amc-img {
                // background-color: $table_titleTop_backgroundColor;
                background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
                border-radius: 10px;
                padding: 10px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .amc-small-images-row {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;

            .amc-small-image {
                position: relative;
                width: 80px;
                height: 80px;
                transition: transform .2s;
                margin: 0 auto;

                &:hover {
                    -ms-transform: scale(1.1);
                    /* IE 9 */
                    -webkit-transform: scale(1.1);
                    /* Safari 3-8 */
                    transform: scale(1.1);
                }

                .amc-img {
                    // background-color: $table_titleTop_backgroundColor;
                    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
                    border-radius: 10px;
                    padding: 5px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .amc-detail-right-part {
        .amc-name {
            margin-bottom: 20px;

            h5 {
                font-size: $fz18;
                font-weight: bold;
            }
        }

        .amc-details-by-row {
            padding-bottom: 10px;
            border-bottom: 2px solid $lightGray;

            .amc-row {
                margin-bottom: 10px;
                font-size: $fz13;
                width: 100%;
                display: flex;

                .pdh {
                    width: 165px !important;
                    max-width: 165px;
                    font-weight: $fwt600;
                    color: $primaryColor;
                }

                .pdd {
                    color: $darkgrayColor;
                    width: 200px !important;
                    max-width: 200px;
                }
            }
        }

        .amc-description {
            margin: 10px 0;

            .description-instruction {
                .description-instruction-nav-tab {
                    .nav-link {
                        color: $darkgrayColor;

                        &.active {
                            color: $primaryColor;
                        }
                    }
                }

                .tab-content {
                    margin-top: 10px;

                    p {
                        word-break: break-word;
                        text-align: justify;
                    }
                }
            }
        }
    }

}

/* ADD & EDIT CLIENT MODEL START */
.add-amc-input {
    border-radius: 5px;
    font-size: 13px;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.amc-model {
    .modal-dialog {
        max-width: 1000px !important;
    }

    .modal-content {
        width: 80% !important;
        margin-left: 90px;
    }

    .add-edit-amc {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .btn-save-up {
            background-color: $primaryColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-amc-heading {
    @include modelHeader;
}



/* SCROLL BAR CSS with Styling START */
/* width */
.complaint-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.complaint-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.complaint-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.complaint-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

.no-dat-td {
    width: 100%;
    position: absolute;
}

.prev-img-div {
    width: 120px;
    margin: 10px 20px;
    border: 1px solid #eee;
}

/* Hide the default file input */
.custom-file-input {
    display: none;
}

/* Style the custom label to look like a button */
.custom-file-label {
    display: inline-block;
    padding: 6px 15px;
    background-color: $secondaryColor;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease;
}

/* Change background color on hover */
.custom-file-label:hover {
    background-color: $secondaryColor;
}

/* Change background color on active (when clicked) */
.custom-file-input:checked+.custom-file-label {
    background-color: $secondaryColor;
}

.choose-file-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding-left: 10px;
    box-shadow: $selectInputShadow !important;
}

/* SCROLL BAR CSS with Styling END */