@import "variables";
@import "mixins";

.sigin-page-body {
    width: 100%;
    height: 100vh;    
}

.dflex-flow-row{
    display: flex;
    flex-direction: row;
    height: 100vh;
    .devider{
        display: flex;
        align-items: center;
        height: 100vh;
        padding: 5px 0px;
        .v1 {
                border-left: 2px solid lightgrey;
                height: 85% !important;
            }
    }
}

.signIn-container-left-div {
    padding: 10px 15px;
    position: relative;
    height: 100%;
}

.signIn-container-right-div {
    padding: 10px 15px;
    position: relative;
    height: 100%;
    background-color: $whiteColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
   

    .metricinfo-logo {
        display: flex;
        justify-content: flex-end;

        .sb-trv-logo {
            width: 15%;
        }
    }
}

.signIn-container-middle-div {
    margin: auto;
}

.signIn-container-gif {
    width: 100%;
    height: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5% 0px auto 10%;
    padding: 5px 20px;

    img {
        width: 100%;
        object-fit: contain;
        margin: 10px auto;        
    }

    .text-sliding-below-img {
        text-align: center;
        min-width: 60%;
        max-width: 100%;
        padding: 5px 10px;
        font-size: 13px;
        .carousel-inner {
                h6 {
                    font-size: 1.35em !important;
                    padding-bottom: 0.25rem;
                }
        
                p {
                    font-size: 1.2em !important;
                }
            }
    }
}


.signIn-container-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .signIn-logo {
        width: 30%;
    }
}

.signIn-container-right-div {
    .signIn-form-control {
        background: $whiteColor;
        border: 1px solid #e0e5eb;
        border-radius: 10px;
        color: #1c273c;
        // font-size: 14px;
        font-weight: 500;
        outline: none;
        padding: 10px 35px;
        transition: 0.15s;
        vertical-align: middle;
        width: 100%;

        &:focus,
        :visited {
            background-color: $whiteColor;
            box-shadow: none !important;
        }
    }
}

input {

    &:-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-background-clip: text !important;
    }
}

.signin-header {
    font-size: 15px;
    &.login-part {
        margin: auto auto auto 0px;
        padding: 5px 0px;
        width: 70%;
        .signin-box{
            display: flex;
            flex-direction: column;
            justify-content: left;
            .whitle-labeling-logo{
                margin-bottom: 1rem;
            }
            .login-header-part {
                margin-bottom: 1.6rem;
                margin-top: -2rem;               
            }
        }
    }

    &.otp-verification-part{
        margin: auto auto auto 0px;
        padding: 5px 0px;
        width: 70%;
        .otp-header-part{
            margin-bottom: 3rem;
            margin-top: -100px;
        }
    }

    &.signup-part {
        margin: auto auto auto 0px;
        padding: 10px 20px 0px 0px;
        width: 75%;

        .recaptcha-part {
            .recaptch-modal-kit {
                display: flex;
                justify-content: center;
                align-items: center;
                transform: scale(0.6);
            }
        }
    }

    &.forgotpass-part {
        margin: auto auto auto 0px;
        padding: 5px 20px 5px 0px;
        width: 75%;

        .forgotpass-header-part {
            margin-bottom: 3rem;
            margin-top: -20px;
        }

        .forgotpass-form-data {
            margin-bottom: 70px;
        }

    }  
    
        &.resetpass-part {
            margin: auto auto auto 0px;
            padding: 5px 20px 5px 0px;
            width: 75%;
            .resetpass-header-part {
                    margin-bottom: 3rem;
                    margin-top: -20px;
                }
            
                .resetpass-form-data {
                    margin-bottom: 70px;
                }
        }

    h2 {
        color: #5842bc;
        font-weight: 500;
        letter-spacing: 0px;
        font-size: 2em !important;        
    }

    h4 {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 1.35em !important;
    }
    p{
        font-size: 1em !important;
    }
    .form-title {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 1em !important;
    }
    .login-form{
        .form-control{
            font-size: 1.2em;
        }
    }
}

.signIn-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;

    &.mt20 {
        margin-top: 1rem;
    }

    a {
        font-weight: 600;
        text-decoration: none;
        font-size: 1em;
    }
}


.icon-with-input {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 12px;

    &.before {
        justify-content: flex-start;

        .fa {
            font-size: 1.2em !important;
            position: absolute;            
            left: 4%;
        }
    }

    .pass-hide-show {
        i {
            position: absolute;
            right: 0;
            top: 1.55%;
            font-size: 0.85em !important;
        }
    }
}

.icon-with-select {
    position: relative;

    &.sbefore {
        .fa {
            font-size: 16px;
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 1;
        }
    }
}

.forgot-pass-n-get-otp {
    padding: 0.25rem;    

    .remember-me{
        .remember-text{
            font-weight: 500;
            font-size: 0.85em !important;
        }
    }

    .forget-pasword-div {
        a {
            font-weight: 500;
            font-size: 0.85em !important;
        }
    }

    .sign-in--using-otp-div {
        .otp-signin {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.signin-button {
    &.mb20 {
        margin-top: 1rem;
    }

    &.mt10 {
        margin-top: 0px;
    }

    &.mt5 {
        margin-top: 1rem;
        margin-bottom: 7rem;
    }

    .btn-signin-button {
        width: 40%;
        height: auto;
        border: none;
        border-radius: 40px;
        color: $whiteColor;
        background-image: $buttonLinerGradient;
        font-size: 1em;

        &.signup {
            width: 60%;
        }

        &:focus,
        :hover {
            box-shadow: none;
            color: $whiteColor;
        }
    }
}

.form-group {
    &.mb20 {
        margin-bottom: 20px;
    }

    &.mt3 {
        margin-top: 1rem;
    }

    input::placeholder {
        color: $commonStatusColor;
    }

    .signIn-form-control {
        box-shadow: none !important;
    }
}

.signup-contry-codes-div {
    .cntry-code {
        width: 100%;

        &:focus:focus-within {
            box-shadow: none;
        }

        .css-13cymwt-control {            
            border: 1px solid #e0e5eb;
            border-radius: 10px;
            color: #1c273c;
            min-width: max-content;
            max-width: max-content;

            &:hover,
            :focus,
            :visited {
                border: 1px solid #e0e5eb;
                border-radius: 10px;                
            }
        }

        .css-t3ipsp-control {
            width: max-content;
        }
    }

    .source-creation {
        &:focus:focus-within {
            box-shadow: none;
        }

        // .css-1fdsijx-ValueContainer, 
        .react-select-list__value-container {
            padding: 2px 28px;
        }

    }

    .css-13cymwt-control {
        line-height: 1;
        border: 1px solid #e0e5eb;
        border-radius: 10px;
        padding: 4px 5px;
        font-size: 1em;

        &:hover,
        :focus,
        :visited {
            border: 1px solid #e0e5eb;
            border-radius: 10px;
            padding: 4px 5px;
            font-size: 1em;
        }
    }

    .css-1u9des2-indicatorSeparator {
        visibility: hidden !important;
    }

    .css-t3ipsp-control {
        border-color: #e0e5eb;
        box-shadow: none;
        border-radius: 10px;
        width: auto;
        padding: 4px 5px;

        &:hover {
            border-color: #e0e5eb;
            box-shadow: none;
            border-radius: 10px;
            padding: 4px 5px;
        }
    }

    .css-1xc3v61-indicatorContainer {
        padding: 1px !important;
    }

    .css-15lsz6c-indicatorContainer {
        padding: 1px !important;
    }

}

.back-to-signin {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    a{
        font-size: 1em;
        &:hover{
            text-decoration: underline;
        }
    }
}

.contry-codes:focus:focus-within {
    box-shadow: none;
}


.contry-codes .css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.contry-codes .css-tj5bde-Svg {
    color: #808090 !important;
}


// OTP VERIFICATION PAGE START
// OtpVarification Page/

.otp-verification-container {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    justify-content: space-between;
}

.otp-verification-container input {
    border-top: none;
    border-right: none;
    border-left: none;
    width: 40px;
    text-align: center;
    font-size: 20px;
}

.otp-verification-container input:focus {
    outline: none;
}

.sign-in-resend-otp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    .sign-in-resend-otp {
        color: $secondaryColor;
        background-color: transparent;
        border: none;
        font-size: $fz12;
        cursor: pointer;
    }
}

.otp-signin {
    background-color: transparent !important;
    padding: 0;
    border: none;
    color: $primaryColor !important;

    &:focus {
        border: none;
        outline: none;
    }
}

.otp-varification-container-sec-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .otp-varification-btn {
        width: 150%;
        height: auto;
        border: none;
        border-radius: 40px;
        color: $whiteColor;
        background-image: $buttonLinerGradient;
    }
}

// OTP VERIFICATION PAGE END

// VHD LOGIN CSS START
.login-with-otp-part{
    .enter-otp{
        display: flex;
        flex-direction: column;
        align-items: center;
        .otp-label{
            font-size: 1.2em;
            margin-bottom: 0.6rem;
            font-weight: 500;
        }
        .form-field-div{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.85rem;
            margin-bottom: 1rem;
            .otp-input-field{
                padding: 1rem;
                width: 2.6rem;
                height: 2.5rem;
            }
        }
    }
}

.back-to-signin-with-pass{
    font-size: 0.85em;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}


// VHD LOGIN CSS END