@import "variables";
@import "mixins";

.url-settings-for-modules {
    position: relative;
    width: 90%;

    #addUrlorPaseUrl {
        min-height: 2.5rem !important;
    }

    .method-options {
        .method-label {
            font-weight: bold;
        }

    }

    .select-param-header-auth {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        .setting-name {
            cursor: pointer;

            .name {
                padding: 0.25rem 0.5rem;
                font-weight: 500;
                color: $blackColor;
                border-radius: 0.5rem;

                &.active {
                    color: $primaryColor;
                    background-color: $primaryVeryLight;
                }
            }
        }
    }

    .enter-settings-details {
        margin-top: 1rem;

        .query-param-part,
        .authorization-part {
            .table-heading {
                color: $blackColor;
                font-weight: bold;
                padding: 0.5rem 0;
            }

            .key-n-value-data {
                border: 1px solid $grayColor;

                .key-n-value-table-head {
                    border-bottom: 1px solid $grayColor;

                    .tb-hd {
                        padding: 0.5rem 1rem;
                        background-color: $primaryVeryLight;
                        font-weight: bold;

                        &:first-child {
                            border-right: 1px solid $grayColor;
                        }
                    }
                }

                .key-n-value-inputs {
                    max-height: 35vh;
                    overflow-x: hidden;

                    .row-values {
                        .remove-row {
                            display: block;
                        }

                        .add-another-row {
                            display: none;
                        }

                        &:last-child {
                            .add-another-row {
                                display: block;
                            }

                            .remove-row {
                                display: none;
                            }
                        }

                        .tb-data {
                            padding: 0.5rem;

                            &:not(:last-child) {
                                border-right: 1px solid $grayColor;
                            }

                            .add-input {
                                min-height: 2.2rem;
                            }

                            .add-another-row,
                            .remove-row {
                                cursor: pointer;
                                padding: 0.25rem 0.5rem;
                                text-align: center;

                                i {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }

            .auth-type-row {
                padding-inline: 0px;
                padding-block: 0.4rem;

                .left,
                .right {
                    min-height: 5rem;
                }

                .left {
                    border-right: 1px solid lightgrey;
                    padding-right: 1.5rem;
                }

                .right {
                    padding-left: 1rem;
                }
            }
        }
    }
}