@import "variables";
@import "mixins";

.vendor-table-part {
    .vendor-table {
        tbody {
            tr {
                td {
                    .active-status, .Active-status {
                        text-transform: capitalize;
                        margin: 0.25rem;
                        @include acceptedStatus;
                    }

                    .inactive-status, .Inactive-status {
                        text-transform: capitalize;
                        margin: 0.25rem;
                        @include cancelledStatus;
                    }
                }
            }
        }
    }
}